import React from "react";
import { Handle, Position } from "reactflow";
import { Department } from "../types/types";

interface DepartmentCardProps {
  data: Department & { onClick: () => void };
}

const DepartmentCard: React.FC<DepartmentCardProps> = ({ data }) => {
  return (
    <div
      className="flex flex-col items-center justify-center p-4 rounded-lg text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 hover:scale-105"
      onClick={data.onClick}
      style={{ width: '200px', height: '100px', textAlign: 'center' }}
    >
      <Handle type="target" position={Position.Top} />
      <div className="cursor-pointer">
        <h3 className="text-lg font-semibold text-white">{data.name}</h3>
        <p className="text-sm text-gray-300">Manager ID: {data.managerId}</p>
      </div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default DepartmentCard;
