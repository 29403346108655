import type { Node, NodeTypes } from "reactflow";
import OrgChartEmployeeCard from "../components/OrgChartEmployeeCard";
import DepartmentCard from "../components/DepartmentCard";

// Initial nodes setup
export const initialNodes = [
  {
    id: "employee-1",
    type: "employee-card",
    position: { x: 500, y: 50 },
    data: {
      name: "Alexandru Irimescu",
      title: "Administrator",
      email: "alexandruIrimescu@gmail.com",
      phone: "07-xxx-xxx-xx",
      startDate: "",
      location: "Bucuresti",
      reportsTo: "",
      img: "https://area4u.ro/wp-content/uploads/2023/02/Imagine-WhatsApp-2023-02-08-la-18.14.3812-150x150.jpg",
      status: "online", // Stare actualizată
      activity: "lucrează la IOT", // Activitate curentă
      setNodeData: (data: any) => {},
    },
  },
  {
    id: "department-1",
    type: "department-card",
    position: { x: 200, y: 200 },
    data: {
      name: "IT",
      managerId: "Vasi V.",
      onClick: () => {},
    },
  },
  {
    id: "department-2",
    type: "department-card",
    position: { x: 500, y: 200 },
    data: {
      name: "Finante",
      managerId: "Ionut",
      onClick: () => {},
    },
  },
  {
    id: "department-3",
    type: "department-card",
    position: { x: 800, y: 200 },
    data: {
      name: "Consultant",
      managerId: "Daniela I.",
      onClick: () => {},
    },
  },
  // Angajații departamentului 1
  {
    id: "employee-2",
    type: "employee-card",
    position: { x: 100, y: 400 },
    data: {
      name: "Alex S",
      title: "Software Dev",
      email: "alexsafta98@gmail.com",
      phone: "07-xxx-xxx-xx",
      startDate: "",
      location: "Bucuresti",
      reportsTo: "department-1",
      img: "https://media.licdn.com/dms/image/C4E03AQFP8eINnlJVxw/profile-displayphoto-shrink_400_400/0/1643828797725?e=1726704000&v=beta&t=RyAfBTFmgu0msjIL-KqtNCqeKAfmg1ZbFq_BUj_D5JY",
      status: "ocupat", // Stare actualizată
      activity: "Rezolvă bug-uri în aplicație", // Activitate curentă
      setNodeData: (data: any) => {},
    },
  },
  {
    id: "employee-3",
    type: "employee-card",
    position: { x: 200, y: 400 },
    data: {
      name: "Vasile V.",
      title: "Software Dev.",
      email: "vasiV@gamil.com",
      phone: "07-xxx-xxx-xx",
      startDate: "",
      location: "Bucuresti",
      reportsTo: "department-1",
      img: "https://media.licdn.com/dms/image/D4E22AQF3kTUkUD0_3Q/feedshare-shrink_2048_1536/0/1698751478599?e=2147483647&v=beta&t=4-qU2pvnSqwFB5lAYvgL4JjWmAYNeYapDLDnYLxZosU",
      status: "online", // Stare actualizată
      activity: "Dezvoltă noi funcționalități pentru proiect", // Activitate curentă
      setNodeData: (data: any) => {},
    },
  },
  {
    id: "employee-4",
    type: "employee-card",
    position: { x: 300, y: 400 },
    data: {
      name: "Vlad N.",
      title: "A.I. Dev.",
      email: "VladN@gmail.com",
      phone: "07-xxx-xxx-xx",
      startDate: "",
      location: "Bucuresti",
      reportsTo: "department-1",
      img: "https://barrjoneslegal.com/wp-content/uploads/2023/07/Mitch-photo-073123.png",
      status: "online", // Stare actualizată
      activity: "Testează algoritmi de inteligență artificială", // Activitate curentă
      setNodeData: (data: any) => {},
    },
  },
  // Angajații departamentului 2
  {
    id: "employee-5",
    type: "employee-card",
    position: { x: 450, y: 400 },
    data: {
      name: "Ionut R",
      title: "Contabil",
      email: "razvan@gmail.com",
      phone: "07-xxx-xxx-xx",
      startDate: "",
      location: "Bucuresti",
      reportsTo: "department-2",
      img: "https://area4u.ro/wp-content/uploads/2023/02/Imagine-WhatsApp-2023-02-08-la-18.26.315-150x150.jpg",
      status: "online", // Stare actualizată
      activity: "lucrează la IOT", // Activitate curentă
      setNodeData: (data: any) => {},
    },
  },
  {
    id: "employee-6",
    type: "employee-card",
    position: { x: 550, y: 400 },
    data: {
      name: "Andra A.",
      title: "Contabil.",
      email: "Andra@gmail.com",
      phone: "07-xxx-xxx-xx",
      startDate: "",
      location: "Bucuresti",
      reportsTo: "department-2",
      img: "https://storage.googleapis.com/pfpai/styles/4a42c413-689e-4c39-a31a-3f141527b1e4.png?X-Goog-Algorithm=GOOG4-RSA-SHA256&X-Goog-Credential=headshotpro-backend-production%40stockai-362303.iam.gserviceaccount.com%2F20240721%2Fauto%2Fstorage%2Fgoog4_request&X-Goog-Date=20240721T020908Z&X-Goog-Expires=518400&X-Goog-SignedHeaders=host&X-Goog-Signature=1222355b336779f30514b911d23f3254eb7c8fa3578158a08c372a8b008a3dd461893756b3f4edff13d01b0f1dcef550887cd45546ba2828da0bf32acac805f38fca05349889bb63ef183231a217c94007a332f1e80244c892a5d372bd6d1545577cb4d42cea802051e11f9f3d7a277e2abc154772d64651adcf8e49f1a0e08226ad65b868296a9d23744e51eddeefcd7747fb5518853357bf54fa5966a539efa313afcba9ac372f79517f012c1b90b0e5a872d72838c39c245cada4d033b0ae13dbf807f93d1cbe7ab2939a707922a89ccafbf54541d34cc77852dd5323ae9c3cb395e0a72c6f2dbc4cf9c43bb6cea8654346ef6f836cb8691e10183df35378",
      status: "ocupat", // Stare actualizată
      activity: "Pregătește raportul financiar lunar", // Activitate curentă
      setNodeData: (data: any) => {},
    },
  },
  {
    id: "employee-7",
    type: "employee-card",
    position: { x: 650, y: 400 },
    data: {
      name: "Bianca B.",
      title: "Contabil",
      email: "biabia@gmail.com",
      phone: "07-xxx-xxx-xx",
      startDate: "",
      location: "Bucuresti",
      reportsTo: "department-2",
      img: "https://media.licdn.com/dms/image/D4E03AQGJ6iOfpgfTtQ/profile-displayphoto-shrink_800_800/0/1706640526658?e=2147483647&v=beta&t=K6k1XCAOebJFZrOXziW8AZjuAIVZoo3vQ_ERstnxbSg",
      status: "ocupat", // Stare actualizată
      activity: "Verifică tranzacțiile contabile", // Activitate curentă
      setNodeData: (data: any) => {},
    },
  },
  // Angajații departamentului 3
  {
    id: "employee-8",
    type: "employee-card",
    position: { x: 800, y: 400 },
    data: {
      name: "Stefania H.",
      title: "Business Consultant",
      email: "StefaniaStefiStef@yahoo.com",
      phone: "07-xxx-xxx-xx",
      startDate: "",
      location: "Bucuresti",
      reportsTo: "department-3",
      img: "https://area4u.ro/wp-content/uploads/2023/02/Imagine-WhatsApp-2023-02-08-la-18.27.518-150x150.jpg",
      status: "indisponibil", // Stare actualizată
      activity: "Analizează datele de piață", // Activitate curentă
      setNodeData: (data: any) => {},
    },
  },
  {
    id: "employee-9",
    type: "employee-card",
    position: { x: 900, y: 400 },
    data: {
      name: "Daniela I.",
      title: "Business Consultant",
      email: "daniela@gmail.com",
      phone: "07-xxx-xxx-xx",
      startDate: "",
      location: "Bucuresti",
      reportsTo: "department-3",
      img: "https://area4u.ro/wp-content/uploads/2023/02/Imagine-WhatsApp-2023-02-08-la-18.26.312-150x150.jpg",
      status: "indisponibil", // Stare actualizată
      activity: "Pregătește prezentarea pentru întâlnirea cu clientul", // Activitate curentă
      setNodeData: (data: any) => {},
    },
  },
  {
    id: "employee-10",
    type: "employee-card",
    position: { x: 1000, y: 400 },
    data: {
      name: "Andreea M.",
      title: "Business Consultant",
      email: "andreeaM@gmail.com",
      phone: "07-xxx-xxx-xx",
      startDate: "",
      location: "Bucuresti",
      reportsTo: "department-3",
      img: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcReJlZqg8h_KEyGmrr6wcjHmxxz6XYL14oX5w&s",
      status: "online", // Stare actualizată
      activity: "Se uită la tutoriale pe YouTube", // Activitate curentă
      setNodeData: (data: any) => {},
    },
  },
] satisfies Node[];

export const nodeTypes = {
  "employee-card": OrgChartEmployeeCard,
  "department-card": DepartmentCard,
} satisfies NodeTypes;
