import React, { useState, ChangeEvent, FormEvent } from "react";
import { Department } from "../types/types";

interface DepartmentDetailProps {
  department: Department;
  onClose: () => void;
  onSave: (updatedDepartment: Department) => void;
  onDelete: () => void;
}

const DepartmentDetailComponent: React.FC<DepartmentDetailProps> = ({ department, onClose, onSave, onDelete }) => {
  const [editableDepartment, setEditableDepartment] = useState<Department>(department);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableDepartment((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSave(editableDepartment);
    onClose();
  };

  return (
    <aside className="fixed top-0 right-0 mr-[5rem] mt-[4.5rem] h-full overflow-hidden rounded-lg border border-solid border-gray-700 bg-gray-800 text-white sm:w-96 shadow-lg">
      <div className="flex justify-end p-2">
        <button className="size-6 cursor-pointer text-gray-400 hover:text-gray-300" onClick={onClose}>X</button>
      </div>

      <form onSubmit={handleSubmit} className="p-4 text-center">
        <h2 className="text-xl font-semibold">
          <input type="text" name="name" value={editableDepartment.name} onChange={handleChange} className="bg-transparent border-b border-gray-600 text-center" />
        </h2>

        <div className="flex flex-col px-4 py-6">
          <div className="mb-2">
            <h3 className="text-sm font-semibold text-gray-400">Details</h3>
          </div>
          <div className="mb-4 grid grid-cols-2 gap-4">
            <div>
              <div className="py-2 text-sm text-gray-400">Name</div>
              <div className="py-2 text-sm text-gray-400">Manager ID</div>
            </div>
            <div>
              <div className="py-2 text-sm text-white">
                <input type="text" name="name" value={editableDepartment.name} onChange={handleChange} className="bg-transparent border-b border-gray-600" />
              </div>
              <div className="py-2 text-sm text-white">
                <input type="text" name="managerId" value={editableDepartment.managerId} onChange={handleChange} className="bg-transparent border-b border-gray-600" />
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center mt-4">
          <button type="button" onClick={onDelete} className="bg-red-500 hover:bg-red-600 text-white rounded py-2 px-4 transition duration-200">Delete</button>
          <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white rounded py-2 px-4 transition duration-200">Save</button>
        </div>
      </form>
    </aside>
  );
};

export default DepartmentDetailComponent;
