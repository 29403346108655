import type { Edge, EdgeTypes } from "reactflow";
import CustomEdge from "../components/CustomEdge";

export const initialEdges: Edge[] = [
  { id: "employee-1->department-1", type: "custom", source: "employee-1", target: "department-1" },
  { id: "employee-1->department-2", type: "custom", source: "employee-1", target: "department-2" },
  { id: "employee-1->department-3", type: "custom", source: "employee-1", target: "department-3" },
  { id: "department-1->employee-2", type: "custom", source: "department-1", target: "employee-2" },
  { id: "department-1->employee-3", type: "custom", source: "department-1", target: "employee-3" },
  { id: "department-1->employee-4", type: "custom", source: "department-1", target: "employee-4" },
  { id: "department-2->employee-5", type: "custom", source: "department-2", target: "employee-5" },
  { id: "department-2->employee-6", type: "custom", source: "department-2", target: "employee-6" },
  { id: "department-2->employee-7", type: "custom", source: "department-2", target: "employee-7" },
  { id: "department-3->employee-8", type: "custom", source: "department-3", target: "employee-8" },
  { id: "department-3->employee-9", type: "custom", source: "department-3", target: "employee-9" },
  { id: "department-3->employee-10", type: "custom", source: "department-3", target: "employee-10" },
];

export const edgeTypes: EdgeTypes = {
  custom: CustomEdge,
};
