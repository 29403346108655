import React, { useCallback, useState, useEffect } from "react";
import ReactFlow, {
  addEdge,
  Controls,
  OnConnect,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { edgeTypes, initialEdges } from "../data/edges";
import { nodeTypes as initialNodeTypes, initialNodes } from "../data/nodes";
import EmployeeDetailComponent from "./EmployeeDetailComponent";
import DepartmentDetailComponent from "./DepartmentDetailComponent";
import { Employee, Department } from "../types/types";
import { saveDataToLocalStorage, loadDataFromLocalStorage } from '../data/localStorageUtils';
import AnnouncementModal from "./AnnouncementModal";
import ChatModal from "./ChatModal";
import NewsModal from "./NewsModal";
import getLayoutedElements from '../utils/dagreHelper';
import convertEmployeesToNodes from '../utils/convertEmployeesToNodes';
import NewEmployeesSection from "./NewEmployeesSection";

const nodeTypes = {
  ...initialNodeTypes,
};

type OrgChartComponentProps = {
  employees: Employee[];
  departments: Department[];
};

const OrgChartComponent: React.FC<OrgChartComponentProps> = ({ employees, departments }) => {
  const [nodes, setNodes, onNodesChange] = useNodesState(loadDataFromLocalStorage('orgchart-nodes') || initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(loadDataFromLocalStorage('orgchart-edges') || initialEdges);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);
  const [selectedDepartment, setSelectedDepartment] = useState<Department | null>(null);
  const [newNodes, setNewNodes] = useState<Employee[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [showNewsModal, setShowNewsModal] = useState(false);
  const [announcement, setAnnouncement] = useState<string | null>(null);
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);
  const [emailList, setEmailList] = useState("");
  const [news, setNews] = useState<string[]>([
    "New company policy announced",
    "Upcoming team building event",
    "Product launch next month"
  ]);
  const reactFlowInstance = useReactFlow();

  useEffect(() => {
    const savedNodes = loadDataFromLocalStorage('orgchart-nodes');
    const savedEdges = loadDataFromLocalStorage('orgchart-edges');
    
    console.log("Loaded nodes:", savedNodes);
    console.log("Loaded edges:", savedEdges);

    if (savedNodes) setNodes(savedNodes);
    if (savedEdges) setEdges(savedEdges);

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(savedNodes || initialNodes, savedEdges || initialEdges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  }, []);

  useEffect(() => {
    saveDataToLocalStorage('orgchart-nodes', nodes);
  }, [nodes]);

  useEffect(() => {
    saveDataToLocalStorage('orgchart-edges', edges);
  }, [edges]);

  const onConnect: OnConnect = useCallback(
    (connection) => {
      const newEdges = addEdge(connection, edges);
      setEdges(newEdges);

      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes, newEdges);
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);
    },
    [edges, nodes]
  );

  const handleNodeClick = (employee: Employee) => {
    setSelectedEmployee(employee);
    setSelectedDepartment(null);
  };

  const handleDepartmentClick = (department: Department) => {
    setSelectedDepartment(department);
    setSelectedEmployee(null);
  };

  const handleCloseDetail = () => {
    setSelectedEmployee(null);
    setSelectedDepartment(null);
  };

  const handleSaveEmployee = (updatedEmployee: Employee) => {
    const updatedNodes = nodes.map((node) =>
      node.id === updatedEmployee.id
        ? {
            ...node,
            data: {
              ...updatedEmployee,
              onClick: () => handleNodeClick(updatedEmployee),
              setNodeData: node.data.setNodeData,
            },
          }
        : node
    );
    setNodes(updatedNodes);

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(updatedNodes, edges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);

    setSelectedEmployee(null);
  };

  const handleSaveDepartment = (updatedDepartment: Department) => {
    const updatedNodes = nodes.map((node) =>
      node.id === updatedDepartment.id
        ? {
            ...node,
            data: {
              ...updatedDepartment,
              onClick: () => handleDepartmentClick(updatedDepartment),
            },
          }
        : node
    );
    setNodes(updatedNodes);

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(updatedNodes, edges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);

    setSelectedDepartment(null);
  };

  const addNode = () => {
    const newEmployee = {
      id: `${Date.now()}`,
      name: "",
      title: "",
      email: "",
      phone: "",
      startDate: new Date().toISOString(),
      location: "",
      img: "",
      reportsTo: "",
      status: "online",
      setNodeData: (data: any) => {},
    };
    setNewNodes((nodes) => [newEmployee, ...nodes]); // Add new employee to the top
  };

  const addDepartment = () => {
    const newDepartment = {
      id: `${Date.now()}`,
      name: "New Department",
      managerId: "",
    };
    const position = reactFlowInstance.project({ x: 250, y: 250 });
    const newNode = {
      id: newDepartment.id,
      type: "department-card",
      position,
      data: {
        ...newDepartment,
        onClick: () => handleDepartmentClick(newDepartment),
      },
      draggable: true,
    };
    setNodes((nds) =>
      nds.concat(newNode)
    );

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(nodes.concat(newNode), edges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  };

  const addMultipleNodes = () => {
    setShowModal(true);
  };

  const handleAddMultipleNodes = () => {
    const emails = emailList.split(/\s+/).filter((email) => email.includes("@"));
    const newEmployees = emails.map((email, index) => {
      const name = email.split("@")[0];
      return {
        id: `${Date.now() + index}`,
        name,
        title: "",
        email,
        phone: "",
        startDate: new Date().toISOString(),
        location: "",
        img: "",
        reportsTo: "",
        status: "online",
        setNodeData: (data: any) => {},
      };
    });

    setNewNodes((nodes) => [...newEmployees, ...nodes]); // Add new employees to the top
    setShowModal(false);
    setEmailList("");

    const convertedNodes = convertEmployeesToNodes(newNodes);
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(convertedNodes, edges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  };

  const deleteNode = () => {
    if (selectedEmployee) {
      const updatedNodes = nodes.filter((node) => node.id !== selectedEmployee.id);
      setNodes(updatedNodes);
      const updatedEdges = edges.filter(
        (edge) =>
          edge.source !== selectedEmployee.id &&
          edge.target !== selectedEmployee.id
      );
      setEdges(updatedEdges);

      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(updatedNodes, updatedEdges);
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);

      setSelectedEmployee(null);
    }
  };

  const deleteDepartment = () => {
    if (selectedDepartment) {
      const updatedNodes = nodes.filter((node) => node.id !== selectedDepartment.id);
      setNodes(updatedNodes);
      const updatedEdges = edges.filter(
        (edge) =>
          edge.source !== selectedDepartment.id &&
          edge.target !== selectedDepartment.id
      );
      setEdges(updatedEdges);

      const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(updatedNodes, updatedEdges);
      setNodes(layoutedNodes);
      setEdges(layoutedEdges);

      setSelectedDepartment(null);
    }
  };

  const handleNodeDragStart = (
    event: React.DragEvent,
    employee: Employee
  ) => {
    event.dataTransfer.setData("application/reactflow", JSON.stringify(employee));
    event.dataTransfer.effectAllowed = "move";
  };

  const handleNodeDrop = (event: React.DragEvent) => {
    event.preventDefault();
    const employee = JSON.parse(
      event.dataTransfer.getData("application/reactflow")
    ) as Employee;
    const position = reactFlowInstance.project({
      x: event.clientX - event.currentTarget.getBoundingClientRect().left,
      y: event.clientY - event.currentTarget.getBoundingClientRect().top,
    });
    const newNode = {
      id: employee.id,
      type: "employee-card",
      position,
      data: {
        ...employee,
        onClick: () => handleNodeClick(employee),
        setNodeData: (data: any) => {},
      },
      draggable: true,
    };
    const updatedNodes = [...nodes, newNode as any];
    setNodes(updatedNodes);
    setNewNodes((nds) => nds.filter((e) => e.id !== employee.id));
    setSelectedEmployee(employee);

    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(updatedNodes, edges);
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
  };

  const onNodeClickHandler = (node: any) => {
    if (node == null) {
      return;
    }

    const isEmployee = node?.type !== "department-card";

    if (isEmployee) {
      handleNodeClick(node?.data as Employee);
      return;
    }

    handleDepartmentClick(node?.data as Department);
  };

  const openAnnouncementModal = () => {
    setShowAnnouncementModal(true);
  };

  const openChatModal = () => {
    setShowChatModal(true);
  };

  const openNewsModal = () => {
    setShowNewsModal(true);
  };

  const handleAnnouncementSubmit = (announcement: string, selectedDepartments: string[]) => {
    setAnnouncement(announcement);
    setSelectedDepartments(selectedDepartments);
    setShowAnnouncementModal(false);
  };

  return (
    <div className="relative h-[100vh] w-full bg-[#252525] text-white overflow-y-scroll no-scrollbar lg:scrollbar">
      <div className="m-6 flex flex-col md:flex-row w-full items-center justify-between space-y-2 md:space-y-0">
        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2">
          <button
            onClick={addNode}
            className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-xs md:text-sm px-4 md:px-5 py-2 text-center"
          >
            + Angajat
          </button>
          <button
            onClick={addDepartment}
            className="text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-800 shadow-lg shadow-green-500/50 dark:shadow-lg dark:shadow-green-800/80 font-medium rounded-lg text-xs md:text-sm px-4 md:px-5 py-2 text-center"
          >
            + Departament
          </button>
          <button
            onClick={addMultipleNodes}
            className="text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 font-medium rounded-lg text-xs md:text-sm px-4 md:px-5 py-2 text-center"
          >
            + Angajati
          </button>
        </div>
        <div className="flex flex-col md:flex-row items-center space-y-2 md:space-y-0 md:space-x-2 mt-4 md:mt-0">
          <button
            onClick={openChatModal}
            className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 shadow-lg shadow-cyan-500/50 dark:shadow-lg dark:shadow-cyan-800/80 font-medium rounded-lg text-xs md:text-sm px-4 md:px-5 py-2 text-center"
          >
            <i className="fas fa-comments text-white"></i>
          </button>
          <button
            onClick={openAnnouncementModal}
            className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 shadow-lg shadow-red-500/50 dark:shadow-lg dark:shadow-red-800/80 font-medium rounded-lg text-xs md:text-sm px-4 md:px-5 py-2 text-center"
          >
            <i className="fas fa-bullhorn text-white"></i>
          </button>
          <button
            onClick={openNewsModal}
            className="text-white bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-purple-300 dark:focus:ring-purple-800 shadow-lg shadow-purple-500/50 dark:shadow-lg dark:shadow-purple-800/80 font-medium rounded-lg text-xs md:text-sm px-4 md:px-5 py-2 text-center"
          >
            <i className="fas fa-newspaper text-white"></i>
          </button>
        </div>
        <div className="flex flex-col md:flex-row mr-[3vw] items-center space-y-2 md:space-y-0 md:space-x-2 mt-4 md:mt-0">
          <button className="focus:shadow-inset flex w-auto items-center justify-center rounded-full border border-solid border-gray-700 px-4 py-1 text-xs md:text-base text-white placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:outline-none transition">
            Vizualizare departament
          </button>
          <button className="focus:shadow-inset flex w-auto items-center justify-center rounded-full border border-solid border-gray-700 bg-white py-1 px-4 text-xs md:text-base font-bold text-black placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:outline-none transition">
            Export
          </button>
        </div>
      </div>
      <div className="flex flex-col md:flex-row h-[calc(100%-64px)]">
        <div className="w-full md:w-1/4 p-4 border-r border-gray-700 bg-[#252525] overflow-y-auto">
          <NewEmployeesSection
            newEmployees={newNodes}
            employees={nodes.filter(node => node.type === "employee-card").map(node => node.data as Employee)}
            handleNodeClick={handleNodeClick}
            handleNodeDragStart={handleNodeDragStart}
          />
        </div>
        <div
          className="relative flex-1 overflow-hidden"
          onDrop={handleNodeDrop}
          onDragOver={(event) => event.preventDefault()}
        >
          <ReactFlow
            nodes={nodes}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            elementsSelectable={true}
            edges={edges}
            edgeTypes={edgeTypes}
            onEdgesChange={onEdgesChange}
            onNodeClick={(_even, node) => onNodeClickHandler(node)}
            onConnect={onConnect}
            snapToGrid={true}
            snapGrid={[15, 15]}
            fitView={true}
            nodesDraggable={true}
            nodesConnectable={true}
            minZoom={0.2}
          >
            <Controls
              style={{
                top: '10px', // Adjust the top position as needed
                left: '10px' // Adjust the left position as needed
              }}
            />
          </ReactFlow>
        </div>
      </div>
      {selectedEmployee && (
        <EmployeeDetailComponent
          employee={selectedEmployee}
          onClose={handleCloseDetail}
          onSave={handleSaveEmployee}
          onDelete={deleteNode}
        />
      )}
      {selectedDepartment && (
        <DepartmentDetailComponent
          department={selectedDepartment}
          onClose={handleCloseDetail}
          onSave={handleSaveDepartment}
          onDelete={deleteDepartment}
        />
      )}
      {showModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl mb-4 text-black">Add Multiple Nodes</h2>
            <textarea
              value={emailList}
              onChange={(e) => setEmailList(e.target.value)}
              placeholder="Enter email addresses separated by spaces or new lines"
              className="w-full h-40 p-2 border rounded text-black"
            />
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowModal(false)}
                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-200 mr-2"
              >
                Cancel
              </button>
              <button
                onClick={handleAddMultipleNodes}
                className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition duration-200"
              >
                Add Nodes
              </button>
            </div>
          </div>
        </div>
      )}
      {showAnnouncementModal && (
        <AnnouncementModal
          departments={departments}
          onClose={() => setShowAnnouncementModal(false)}
          onSubmit={handleAnnouncementSubmit}
        />
      )}
      {announcement && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-gradient-to-r from-red-400 via-red-500 to-red-600 p-6 rounded-lg shadow-md bg-opacity-90 w-[90vw] md:w-[40vw] text-center neon-border">
            <h2 className="text-xl mb-4 text-white">Announcement</h2>
            <p className="text-white">{announcement}</p>
            <button
              onClick={() => setAnnouncement(null)}
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-200 mt-4"
            >
              OK
            </button>
          </div>
        </div>
      )}
      {showChatModal && (
        <ChatModal onClose={() => setShowChatModal(false)} />
      )}
      {showNewsModal && (
        <NewsModal onClose={() => setShowNewsModal(false)} news={news} />
      )}
    </div>
  );
};

export default OrgChartComponent;
