import React from "react";
import { Employee } from "../types/types";

interface NewEmployeesSectionProps {
  newEmployees: Employee[];
  employees: Employee[];
  handleNodeClick: (employee: Employee) => void;
  handleNodeDragStart: (event: React.DragEvent, employee: Employee) => void;
}

const getStatusColor = (status: string) => {
  switch (status) {
    case "online":
      return "bg-green-600";
    case "indisponibil":
      return "bg-red-600";
    case "ocupat":
      return "bg-yellow-600";
    default:
      return "bg-gray-600";
  }
};

const NewEmployeesSection: React.FC<NewEmployeesSectionProps> = ({ newEmployees, employees, handleNodeClick, handleNodeDragStart }) => {
  return (
    <div>
      <h2 className="text-xl mb-4">Angajați noi</h2>
      <div className="mt-4">
        {/* <h2 className="text-xl mb-4">Noi Angajați</h2> */}
        {newEmployees.map(employee => (
          <div
            key={employee.id}
            className={`cursor-pointer p-2 mb-2 bg-gray-800 text-white rounded-lg flex items-center shadow-md transition`}
            onClick={() => handleNodeClick(employee)}
            draggable
            onDragStart={(event) => handleNodeDragStart(event, employee)}
          >
            <img
              src={employee.img || "https://via.placeholder.com/150"}
              alt={employee.name}
              className="w-10 h-10 rounded-full mr-2"
            />
            {employee.name || "New Employee"}
          </div>
        ))}
      </div>
      <div className="mb-4">
        <h3 className="text-lg">Online</h3>
        {employees.filter(emp => emp.status === "online").map(employee => (
          <div
            key={employee.id}
            className={`cursor-pointer p-2 mb-2 text-white rounded-lg flex items-center shadow-md transition ${getStatusColor(employee.status || "offline")}`}
            onClick={() => handleNodeClick(employee)}
            draggable
            onDragStart={(event) => handleNodeDragStart(event, employee)}
          >
            <img
              src={employee.img || "https://via.placeholder.com/150"}
              alt={employee.name}
              className="w-10 h-10 rounded-full mr-2"
            />
            {employee.name}
          </div>
        ))}
      </div>
      <div className="mb-4">
        <h3 className="text-lg">Indisponibil</h3>
        {employees.filter(emp => emp.status === "indisponibil").map(employee => (
          <div
            key={employee.id}
            className={`cursor-pointer p-2 mb-2 text-white rounded-lg flex items-center shadow-md transition ${getStatusColor(employee.status || "offline")}`}
            onClick={() => handleNodeClick(employee)}
            draggable
            onDragStart={(event) => handleNodeDragStart(event, employee)}
          >
            <img
              src={employee.img || "https://via.placeholder.com/150"}
              alt={employee.name}
              className="w-10 h-10 rounded-full mr-2"
            />
            {employee.name}
          </div>
        ))}
      </div>
      <div className="mb-4">
        <h3 className="text-lg">Ocupat</h3>
        {employees.filter(emp => emp.status === "ocupat").map(employee => (
          <div
            key={employee.id}
            className={`cursor-pointer p-2 mb-2 text-white rounded-lg flex items-center shadow-md transition ${getStatusColor(employee.status || "offline")}`}
            onClick={() => handleNodeClick(employee)}
            draggable
            onDragStart={(event) => handleNodeDragStart(event, employee)}
          >
            <img
              src={employee.img || "https://via.placeholder.com/150"}
              alt={employee.name}
              className="w-10 h-10 rounded-full mr-2"
            />
            {employee.name}
          </div>
        ))}
      </div>
      {/* <div className="mt-4">
        <h2 className="text-xl mb-4">Noi Angajați</h2>
        {newEmployees.map(employee => (
          <div
            key={employee.id}
            className={`cursor-pointer p-2 mb-2 bg-green-600 text-white rounded-lg flex items-center shadow-md transition`}
            onClick={() => handleNodeClick(employee)}
            draggable
            onDragStart={(event) => handleNodeDragStart(event, employee)}
          >
            <img
              src={employee.img || "https://via.placeholder.com/150"}
              alt={employee.name}
              className="w-10 h-10 rounded-full mr-2"
            />
            {employee.name || "New Employee"}
          </div>
        ))}
      </div> */}
    </div>
  );
};

export default NewEmployeesSection;
