import React from "react";
import { Handle, Position } from "reactflow";
import { Employee } from "../types/types";

interface OrgChartEmployeeCardProps {
  data: Employee & { setNodeData: (data: any) => void; onClick: () => void; };
}

const OrgChartEmployeeCard: React.FC<OrgChartEmployeeCardProps> = ({ data }) => {
  const getStatusClass = () => {
    if (data.status === "indisponibil") {
      return "border-indisponibil animate-pulse";
    }
    if (data.status === "ocupat") {
      return "border-ocupat animate-pulse";
    }
    if (data.status === "online") {
      return "border-online animate-pulse";
    }
    return "";
  };

  const getStatusText = () => {
    if (data.status === "indisponibil") {
      return "Indisponibil";
    }
    if (data.status === "ocupat") {
      return "Ocupat";
    }
    if (data.status === "online") {
      return "Online";
    }
    return "Necunoscut";
  };

  return (
    <div
      className="flex flex-col items-center justify-center p-6 rounded-lg shadow-lg bg-white border border-gray-200 transition duration-300 transform hover:scale-105 cursor-pointer"
      onClick={data.onClick}
      style={{ width: '250px', textAlign: 'center' }}
    >
      <Handle type="target" position={Position.Top} />
      <div className="relative cursor-pointer">
        <div className={`w-24 h-24 rounded-full border-4 mb-4 flex items-center justify-center ${getStatusClass()}`}>
          <img
            src={data.img}
            alt={data.name}
            className="w-20 h-20 rounded-full"
          />
        </div>
      </div>
      <h3 className="text-lg font-semibold text-blue-600">{data.name}</h3>
      <p className="text-sm font-semibold text-white bg-blue-500 rounded-full px-2 py-1 mt-2">{data.title}</p>
      <p className="text-sm text-gray-600 mt-1">{data.activity}</p> {/* Afișează activitatea */}
      <p className={`text-sm mt-1`}>{getStatusText()}</p> {/* Afișează textul stării */}
      <div className="flex justify-around mt-4 w-full px-6">
        <i className="fas fa-comments text-blue-500"></i>
        <i className="fas fa-bullhorn text-blue-500"></i>
        <i className="fas fa-newspaper text-blue-500"></i>
      </div>
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default OrgChartEmployeeCard;
