import React from "react";

const SearchComponent: React.FC = () => {
  return (
    <div className="relative flex">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          className="size-5 text-gray-500"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      </div>
      <input
        type="search"
        className="text-surface relative m-0 block w-[25vw] flex-auto rounded-full border border-solid border-[#6b7280] bg-transparent bg-clip-padding py-[0.25rem] pl-10 text-base font-normal leading-[1.6] outline-none transition duration-200"
        placeholder="Search..."
        aria-label="Search"
      />
    </div>
  );
};

export default SearchComponent;
