import React from "react";

interface NewsModalProps {
  onClose: () => void;
  news: string[];
}

const NewsModal: React.FC<NewsModalProps> = ({ onClose, news }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gradient-to-r from-purple-500 via-purple-600 to-purple-700 p-6 rounded-lg shadow-md bg-opacity-90 w-[90vw] md:w-[40vw]">
        <h2 className="text-xl mb-4 text-white">Știri</h2>
        <div className="h-64 overflow-y-scroll bg-black p-4 rounded-lg mb-4">
          {news.map((article, index) => (
            <div key={index} className="text-white mb-2">{article}</div>
          ))}
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={onClose}
            className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-200"
          >
            Închide
          </button>
        </div>
      </div>
    </div>
  );
};

export default NewsModal;
