import React, { useState, ChangeEvent, FormEvent } from "react";
import { Icons } from "../assets/icons";
import { Employee, Document } from "../types/types";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import './CustomCalendar.css'; // Importăm CSS-ul personalizat

interface EmployeeDetailProps {
  employee: Employee;
  onClose: () => void;
  onSave: (updatedEmployee: Employee) => void;
  onDelete: () => void;
}

interface Task {
  text: string;
  completed: boolean;
}

interface Meeting {
  date: string;
  title: string;
}

const dummyDocuments: Document[] = [
  { content: 'https://via.placeholder.com/150', name: 'Contract de muncă', type: 'pdf' },
  { content: 'https://via.placeholder.com/150', name: 'Fișa postului', type: 'pdf' },
  { content: 'https://via.placeholder.com/150', name: 'Imagine buletin', type: 'image' }
];

const EmployeeDetailComponent: React.FC<EmployeeDetailProps> = ({ employee, onClose, onSave, onDelete }) => {
  const [isPinned, setIsPinned] = useState(false);
  const [editableEmployee, setEditableEmployee] = useState<Employee>({ ...employee, documents: [...dummyDocuments, ...(employee.documents || [])] });
  const [selectedDoc, setSelectedDoc] = useState<string | null>(null);
  const [newApplicationUrl, setNewApplicationUrl] = useState<string>("");
  const [tasks, setTasks] = useState<Task[]>([]);
  const [newTask, setNewTask] = useState<string>("");
  const [meetings, setMeetings] = useState<Meeting[]>([]);
  const [newMeeting, setNewMeeting] = useState<Meeting>({ date: "", title: "" });
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [showMeetingModal, setShowMeetingModal] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting | null>(null);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableEmployee((prev) => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setEditableEmployee((prev) => ({ ...prev, img: reader.result as string }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDocUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const newDocument: Document = {
          content: reader.result as string,
          name: file.name,
          type: file.type
        };
        setEditableEmployee((prev) => ({
          ...prev,
          documents: [...(prev.documents || []), newDocument],
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleApplicationAdd = () => {
    if (newApplicationUrl) {
      setEditableEmployee((prev) => ({
        ...prev,
        applications: [...(prev.applications || []), { url: newApplicationUrl }],
      }));
      setNewApplicationUrl("");
    }
  };

  const handleTaskAdd = () => {
    if (newTask) {
      setTasks((prev) => [...prev, { text: newTask, completed: false }]);
      setNewTask("");
    }
  };

  const handleTaskToggle = (index: number) => {
    setTasks((prev) =>
      prev.map((task, i) => (i === index ? { ...task, completed: !task.completed } : task))
    );
  };

  const handleTaskDelete = (index: number) => {
    setTasks((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSave(editableEmployee);
    onClose(); // Close the detail card after saving
  };

  const handleDocClick = (doc: Document) => {
    setSelectedDoc(doc.content);
  };

  const closeModal = () => {
    setSelectedDoc(null);
  };

  const handleMeetingChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewMeeting((prev) => ({ ...prev, [name]: value }));
  };

  const handleMeetingAdd = (e: FormEvent) => {
    e.preventDefault();
    if (newMeeting.date && newMeeting.title) {
      setMeetings((prev) => [...prev, newMeeting]);
      setNewMeeting({ date: "", title: "" });
      setShowMeetingModal(false);
    }
  };

  const handleDateClick = (date: Date) => {
    const dayString = formatDate(date);
    const meeting = meetings.find(meeting => meeting.date === dayString);
    if (meeting) {
      setSelectedMeeting(meeting);
    } else {
      setSelectedDate(date);
      setShowMeetingModal(true);
      setNewMeeting((prev) => ({ ...prev, date: dayString }));
    }
  };

  const formatDate = (date: Date) => {
    return date.toISOString().split('T')[0];
  }

  const handleMeetingUpdate = (e: FormEvent) => {
    e.preventDefault();
    setMeetings((prev) => prev.map(meeting => meeting.date === selectedMeeting?.date ? selectedMeeting : meeting));
    setSelectedMeeting(null);
  };

  const handleMeetingDelete = () => {
    setMeetings((prev) => prev.filter(meeting => meeting.date !== selectedMeeting?.date));
    setSelectedMeeting(null);
  };

  const renderSelectedDocument = () => {
    if (selectedDoc == null) {
      return null;
    }

    const PDF_STRING = 'data:application/pdf;base64';
    const isPdf = selectedDoc.includes(PDF_STRING);

    if (isPdf) {
      return  <embed src={selectedDoc} className="h-full w-full" />
    }

    return <img src={selectedDoc} alt="Document" className="h-auto" />
  }

  const getIconByFileType = (type: string) => {
    if (type.includes("pdf")) {
      return "https://play-lh.googleusercontent.com/DynUYgOThKVnORSvd0ZN2_29QcuWSo3YnbtBYbq49MM3MXol11uXERTIURFdOJ_PdA";
    }
    if (type.includes("word")) {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/8/87/Microsoft_Office_Word_%282019%E2%80%93present%29.svg/2048px-Microsoft_Office_Word_%282019%E2%80%93present%29.svg.png";
    }
    if (type.includes("excel")) {
      return "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Microsoft_Excel_2013-2019_logo.svg/2048px-Microsoft_Excel_2013-2019_logo.svg.png";
    }
    return "https://via.placeholder.com/64"; // Default icon
  }

  return (
    <aside className="fixed top-0 right-0 mr-[5rem] mt-[4.5rem] h-[90%] overflow-hidden rounded-lg border border-solid border-gray-700 bg-gray-800 text-white sm:w-96 shadow-lg">
      <div className="flex justify-end p-2">
        <Icons.OrganigramPin
          className={`size-6 cursor-pointer ${isPinned ? 'text-gray-300' : 'text-gray-400'} hover:text-gray-300`}
          onClick={() => setIsPinned(!isPinned)}
        />
        <Icons.OrganigramMin
          className="size-6 cursor-pointer text-gray-400 hover:text-gray-300"
          onClick={onClose}
        />
      </div>

      <div className="overflow-y-scroll no-scrollbar h-[calc(100%-2rem)] p-4">
        <form onSubmit={handleSubmit} className="text-center">
          <div className="relative mx-auto mb-2 flex items-center justify-center">
            <img src={editableEmployee.img || "https://via.placeholder.com/150"} alt="Employee" className="w-20 h-20 rounded-full border-4 border-green-500 animate-pulse" />
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-full opacity-0 hover:opacity-100">
              <label className="cursor-pointer text-white">
                Schimbă
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  className="hidden"
                />
              </label>
            </div>
          </div>
          <input
            type="text"
            name="img"
            value={editableEmployee.img}
            onChange={handleChange}
            className="mt-2 w-full bg-transparent border-b border-gray-600 text-center"
            placeholder="URL Imagine"
          />
          <h2 className="text-xl font-semibold">
            <input type="text" name="name" value={editableEmployee.name} onChange={handleChange} className="bg-transparent border-b border-gray-600 text-center" />
          </h2>
          <p className="text-sm text-gray-400">
            <input type="text" name="title" value={editableEmployee.title} onChange={handleChange} className="bg-transparent border-b border-gray-600 text-center" />
          </p>

          <div className="flex flex-col px-4 py-6">
            <div className="mb-2">
              <h3 className="text-sm font-semibold text-gray-400">Detalii</h3>
            </div>
            <div className="mb-4 grid grid-cols-2 gap-4">
              <div>
                <div className="py-2 text-sm text-gray-400">Nume</div>
                <div className="py-2 text-sm text-gray-400">Titlu job</div>
                <div className="py-2 text-sm text-gray-400">Raportează către</div>
                <div className="py-2 text-sm text-gray-400">Locație</div>
              </div>
              <div>
                <div className="py-2 text-sm text-white">
                  <input type="text" name="name" value={editableEmployee.name} onChange={handleChange} className="bg-transparent border-b border-gray-600" />
                </div>
                <div className="py-2 text-sm text-white">
                  <input type="text" name="title" value={editableEmployee.title} onChange={handleChange} className="bg-transparent border-b border-gray-600" />
                </div>
                <div className="py-2 text-sm text-white">
                  <input type="text" name="reportsTo" value={editableEmployee.reportsTo} onChange={handleChange} className="bg-transparent border-b border-gray-600" />
                </div>
                <div className="py-2 text-sm text-white">
                  <input type="text" name="location" value={editableEmployee.location} onChange={handleChange} className="bg-transparent border-b border-gray-600" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col border-t border-gray-600 p-4 py-6">
            <div className="grid grid-cols-2 gap-4">
              <div>
                <div className="py-2 text-sm text-gray-400">Data începutului</div>
                <div className="py-2 text-sm text-gray-400">Mobil</div>
                <div className="py-2 text-sm text-gray-400">Email</div>
              </div>
              <div>
                <div className="py-2 text-sm text-white">
                  <input type="text" name="startDate" value={editableEmployee.startDate} onChange={handleChange} className="bg-transparent border-b border-gray-600" />
                </div>
                <div className="py-2 text-sm text-white">
                  <input type="text" name="phone" value={editableEmployee.phone} onChange={handleChange} className="bg-transparent border-b border-gray-600" />
                </div>
                <div className="py-2 text-sm text-white">
                  <input type="text" name="email" value={editableEmployee.email} onChange={handleChange} className="bg-transparent border-b border-gray-600" />
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center mt-4">
            <button type="button" onClick={onDelete} className="bg-red-500 hover:bg-red-600 text-white rounded py-2 px-4 transition duration-200">Șterge</button>
            <button type="submit" className="bg-blue-500 hover:bg-blue-600 text-white rounded py-2 px-4 transition duration-200">Salvează</button>
          </div>
        </form>

        {/* Documents Section */}
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-2">Documente</h3>
          <div className="flex space-x-4">
            {editableEmployee.documents && editableEmployee.documents.map((doc, index) => (
              <div key={index} className="flex flex-col items-center">
                <img
                  src={getIconByFileType(doc.type)}
                  alt={doc.name}
                  className="w-16 h-16 cursor-pointer"
                  onClick={() => handleDocClick(doc)}
                />
                <p className="text-sm mt-2">{doc.name}</p>
              </div>
            ))}
            <label className="w-16 h-16 rounded-full border border-dashed border-gray-400 flex items-center justify-center cursor-pointer">
              <span className="text-2xl">+</span>
              <input
                type="file"
                accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
                onChange={handleDocUpload}
                className="hidden"
              />
            </label>
          </div>
        </div>

        {/* Applications Section */}
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-2">Aplicații</h3>
          <div className="flex space-x-4">
            {editableEmployee.applications && editableEmployee.applications.map((app, index) => (
              <a key={index} href={app.url} target="_blank" rel="noopener noreferrer">
                <img
                  src={`https://www.google.com/s2/favicons?sz=64&domain_url=${app.url}`}
                  alt={`Aplicația ${index + 1}`}
                  className="w-16 h-16 rounded-full"
                />
              </a>
            ))}
            <div className="flex space-x-2">
              <input
                type="text"
                value={newApplicationUrl}
                onChange={(e) => setNewApplicationUrl(e.target.value)}
                className="bg-transparent text-white border-b border-gray-600"
                placeholder="Adaugă URL"
              />
              <button onClick={handleApplicationAdd} className="text-2xl text-white">+</button>
            </div>
          </div>
        </div>

        {/* Calendar Section */}
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-2">Calendar</h3>
          <Calendar
            onClickDay={handleDateClick}
            value={selectedDate}
            tileContent={({ date, view }) => {
              const dayString = formatDate(date);
              const meeting = meetings.find(meeting => meeting.date === dayString);
              return meeting ? <div className="react-calendar__tile--meeting">{meeting.title}</div> : null;
            }}
          />
        </div>

        {/* Tasks (To-Do) Section */}
        <div className="p-4">
          <h3 className="text-lg font-semibold mb-2">Sarcini (To-Do)</h3>
          <div>
            <input
              type="text"
              value={newTask}
              onChange={(e) => setNewTask(e.target.value)}
              className="bg-transparent text-white border-b border-gray-600"
              placeholder="Sarcină nouă"
            />
            <button onClick={handleTaskAdd} className="ml-2 bg-blue-500 hover:bg-blue-600 text-white rounded py-1 px-2 transition duration-200">Adaugă</button>
          </div>
          <ul className="mt-4">
            {tasks.map((task, index) => (
              <li key={index} className="flex justify-between items-center py-2 border-b border-gray-600">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    checked={task.completed}
                    onChange={() => handleTaskToggle(index)}
                    className="mr-2"
                  />
                  <span className={task.completed ? "line-through" : ""}>{task.text}</span>
                </div>
                <button onClick={() => handleTaskDelete(index)} className="text-red-500 hover:text-red-600">Șterge</button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {selectedDoc && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 py-12 h-dvh my-12">
          <div className="flex bg-white p-6 rounded-lg relative h-full aspect-square justify-center items-center">
            <button className="absolute top-2 right-2 text-black" onClick={closeModal}>X</button>
            {renderSelectedDocument()}
          </div>
        </div>
      )}

      {showMeetingModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl mb-4 text-black">Adaugă întâlnire</h2>
            <form onSubmit={handleMeetingAdd}>
              <input
                type="date"
                name="date"
                value={newMeeting.date}
                onChange={handleMeetingChange}
                className="bg-transparent text-black border-b border-gray-600 mr-2"
              />
              <input
                type="text"
                name="title"
                value={newMeeting.title}
                onChange={handleMeetingChange}
                className="bg-transparent text-black border-b border-gray-600"
                placeholder="Titlu eveniment"
              />
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowMeetingModal(false)}
                  className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-200 mr-2"
                >
                  Anulează
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-200"
                >
                  Adaugă
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {selectedMeeting && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white p-6 rounded-lg shadow-md">
            <h2 className="text-xl mb-4 text-black">Editează întâlnire</h2>
            <form onSubmit={handleMeetingUpdate}>
              <input
                type="date"
                name="date"
                value={selectedMeeting.date}
                onChange={(e) => setSelectedMeeting(prev => prev ? { ...prev, date: e.target.value } : null)}
                className="bg-transparent text-black border-b border-gray-600 mr-2"
              />
              <input
                type="text"
                name="title"
                value={selectedMeeting.title}
                onChange={(e) => setSelectedMeeting(prev => prev ? { ...prev, title: e.target.value } : null)}
                className="bg-transparent text-black border-b border-gray-600"
                placeholder="Titlu eveniment"
              />
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setSelectedMeeting(null)}
                  className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-200 mr-2"
                >
                  Anulează
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-200"
                >
                  Actualizează
                </button>
                <button
                  type="button"
                  onClick={handleMeetingDelete}
                  className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded transition duration-200 ml-2"
                >
                  Șterge
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </aside>
  );
};

export default EmployeeDetailComponent;
