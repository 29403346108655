import React from 'react';
import { BaseEdge, EdgeProps, getSmoothStepPath, Position } from "reactflow";

export type CustomEdgeData = {
  id?: string;
  sourceX?: number;
  sourceY?: number;
  targetX?: number;
  targetY?: number;
};

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
}: EdgeProps<CustomEdgeData>) => {
  const [edgePath] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition: Position.Bottom,
    targetX,
    targetY,
    targetPosition: Position.Top,
    borderRadius: 50,
  });

  return (
    <React.Fragment>
      <BaseEdge id={id} path={edgePath} />
    </React.Fragment>
  );
};

export default CustomEdge;
