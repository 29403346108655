import React, { ChangeEvent, useState } from "react";
import { Icons } from "../assets/icons";

import SearchComponent from "./SearchComponent";

const HeaderComponent = () => {
  const [sliderValue, setSliderValue] = useState<number>(2023);
  const min = 2020;
  const max = 2024;

  const handleSliderChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSliderValue(Number(event.target.value));
  };

  const sliderPercentage = ((sliderValue - min) / (max - min)) * 100;

  return (
    <header className="mt-2 flex items-center justify-between border-b border-[#6b7280] bg-[#252525] p-4 text-white">
      <div className="flex items-center space-x-4">
        {/* <button className="focus:shadow-inset flex w-[9rem] items-center justify-center rounded-full border border-solid border-[#6b7280] py-[0.25rem] text-base placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:outline-none motion-reduce:transition-none">
          <Icons.OrganigramHierarchy className="mr-2 size-4" />
          Primary
          <Icons.OrganigramDown className="ml-2 size-4" />
        </button> */}

        <div className="flex items-center justify-center rounded-full border border-solid border-[#6b7280]">
          <div className="juntify-center h-[2rem] grow items-center px-6">
            <input
              type="range"
              min={min}
              max={max}
              value={sliderValue}
              onChange={handleSliderChange}
              className="thumb slider mt-[7px] flex h-1 w-[14rem] appearance-none items-center justify-center rounded-full outline-none transition-colors"
              style={{
                background: `linear-gradient(to left, #5aa2f6 0%, #5aa2f6 ${
                  100 - sliderPercentage
                }%, #4B5563 ${100 - sliderPercentage}%, #4B5563 100%)`,
              }}
            />
            <div className="mt-[3px] flex w-full items-center justify-between pb-1 text-xs">
              {["2020", "2021", "2022", "2023", "2024"].map((year, index) => (
                <span key={index} className="px-2 text-gray-300">
                  {year}
                </span>
              ))}
            </div>
          </div>

          <div className="flex items-center space-x-2">
            <button className="p-2">
              <Icons.OrganigramLeftArrow />
            </button>
            <span className="whitespace-nowrap">2024</span>
            <button className="p-2">
              <Icons.OrganigramRightArrow />
           
              <Icons.OrganigramRightArrow />
            </button>
          </div>
        </div>

        {/* <button className="text-surface focus:shadow-inset flex w-[5rem] items-center justify-center rounded-full border border-solid border-[#6b7280] bg-transparent bg-clip-padding py-[0.25rem] text-base font-normal leading-[1.6] outline-none transition duration-200 ease-in-out placeholder:text-neutral-500 focus:z-[3] focus:border-primary focus:outline-none motion-reduce:transition-none">
          <Icons.OrganigramVector className="mr-2 size-4" />
          0
          <Icons.OrganigramDown className="ml-2 size-4" />
        </button> */}

        <div className="ml-4">
          <SearchComponent />
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
