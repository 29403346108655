import React, { useState } from "react";
import EmployeeDetailComponent from "../components/EmployeeDetailComponent";
import HeaderComponent from "../components/HeaderComponent";
import OrgChartComponent from "../components/OrgChartComponent";
import { Employee, Department } from "../types/types";
import { ReactFlowProvider } from "reactflow";

const initialEmployees: Employee[] = [
  {
    id: "1",
    name: "John Doe",
    title: "Software Engineer",
    email: "john.doe@example.com",
    phone: "123-456-7890",
    startDate: "2023-01-01",
    location: "New York",
    reportsTo: "2",
    img: "https://via.placeholder.com/150",
  },
  {
    id: "2",
    name: "Jane Smith",
    title: "Engineering Manager",
    email: "jane.smith@example.com",
    phone: "098-765-4321",
    startDate: "2022-01-01",
    location: "San Francisco",
    reportsTo: "",
    img: "https://via.placeholder.com/150",
  },
];

const initialDepartments: Department[] = [
  {
    id: "1",
    name: "Engineering",
    managerId: "2",
  },
];

const Organigram: React.FC = () => {
  const [employees, setEmployees] = useState<Employee[]>(initialEmployees);
  const [departments, setDepartments] = useState<Department[]>(initialDepartments);
  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);

  const handleSaveEmployee = (updatedEmployee: Employee) => {
    setEmployees((prevEmployees) =>
      prevEmployees.map((employee) =>
        employee.id === updatedEmployee.id ? updatedEmployee : employee
      )
    );
  };

  const handleDeleteEmployee = () => {
    if (selectedEmployee) {
      setEmployees((prevEmployees) =>
        prevEmployees.filter((employee) => employee.id !== selectedEmployee.id)
      );
      setSelectedEmployee(null);
    }
  };

  if (!employees.length) {
    return <div>Loading...</div>;
  }

  return (
    <ReactFlowProvider>
      <div className="h-[100%] w-[100%] overflow-hidden bg-[#252525]">
        <HeaderComponent />
        <div className="main-content flex size-full flex-row items-center justify-center">
          <OrgChartComponent employees={employees} departments={departments} />
          {selectedEmployee && (
            <EmployeeDetailComponent
              employee={selectedEmployee}
              onClose={() => setSelectedEmployee(null)}
              onSave={handleSaveEmployee}
              onDelete={handleDeleteEmployee}
            />
          )}
        </div>
      </div>
    </ReactFlowProvider>
  );
};

export default Organigram;
