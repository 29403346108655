import { Node } from "reactflow";
import { Employee } from "../types/types";

const convertEmployeesToNodes = (employees: Employee[]): Node[] => {
  return employees.map((employee) => ({
    id: employee.id,
    type: "employee-card",
    position: { x: 0, y: 0 }, // Position will be set by layout
    data: {
      ...employee,
      onClick: () => {},
      setNodeData: (data: any) => {},
    },
  }));
};

export default convertEmployeesToNodes;
