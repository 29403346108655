import React, { useState, ChangeEvent, FormEvent } from "react";
import { Department } from "../types/types";

interface AnnouncementModalProps {
  departments: Department[];
  onClose: () => void;
  onSubmit: (announcement: string, selectedDepartments: string[]) => void;
}

const AnnouncementModal: React.FC<AnnouncementModalProps> = ({ departments, onClose, onSubmit }) => {
  const [announcement, setAnnouncement] = useState("");
  const [selectedDepartments, setSelectedDepartments] = useState<string[]>([]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setAnnouncement(e.target.value);
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setSelectedDepartments(prev => 
      checked ? [...prev, value] : prev.filter(dep => dep !== value)
    );
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    onSubmit(announcement, selectedDepartments);
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gradient-to-r from-red-400 via-red-500 to-red-600 p-6 rounded-lg shadow-md bg-opacity-90 neon-border w-[90vw] md:w-[40vw]">
        <h2 className="text-xl mb-4 text-white">Anunț Nou</h2>
        <form onSubmit={handleSubmit}>
          <textarea
            value={announcement}
            onChange={handleChange}
            className="w-full h-40 p-2 border rounded text-black "
            placeholder="Introdu anunțul tău aici..."
          />
          <div className="mt-4">
            <h3 className="text-lg text-white mb-2">Selectează Departamente</h3>
            <div className="max-h-40 overflow-y-scroll">
              {departments.map(department => (
                <div key={department.id} className="flex items-center">
                  <input
                    type="checkbox"
                    value={department.id}
                    onChange={handleCheckboxChange}
                    className="mr-2"
                  />
                  <label className="text-white">{department.name}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={onClose} 
              className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-200 mr-2 "
            >
              Anulează
            </button>
            <button
              type="submit"
              className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded transition duration-200"
            >
              Trimite
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AnnouncementModal;
