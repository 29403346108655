import React, { useState, ChangeEvent, FormEvent } from "react";

interface ChatModalProps {
  onClose: () => void;
}

const ChatModal: React.FC<ChatModalProps> = ({ onClose }) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState<string[]>([]);

  const handleMessageChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };

  const handleSendMessage = (e: FormEvent) => {
    e.preventDefault();
    if (message.trim()) {
      setMessages([...messages, message]);
      setMessage("");
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 p-6 rounded-lg shadow-md bg-opacity-90 w-[90vw] md:w-[40vw]">
        <h2 className="text-xl mb-4 text-white">Chat Intern</h2>
        <div className="h-64 overflow-y-scroll bg-black p-4 rounded-lg mb-4">
          {messages.map((msg, index) => (
            <div key={index} className="text-white mb-2">{msg}</div>
          ))}
        </div>
        <form onSubmit={handleSendMessage} className="flex flex-col">
          <textarea
            value={message}
            onChange={handleMessageChange}
            className="w-full h-20 p-2 border rounded text-black"
            placeholder="Scrie mesajul tău aici..."
          />
          <div className="flex justify-end mt-4">
            <button
              type="button"
              onClick={onClose}
              className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded transition duration-200 mr-2"
            >
              Anulează
            </button>
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded transition duration-200"
            >
              Trimite
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChatModal;
